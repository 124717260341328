export type ThemeColor = typeof DefaultColors;
export type ThemeColorKey = keyof ThemeColor;

export const DefaultColors = {
  // New kognic theme colors
  // Primary colors (comment is which color it commonly replaces)
  black: '#000', // black
  dark: '#0d0d0d', // annotellDark
  primary90: '#1a1a1a', // shadow97
  primary88: '#262626', // Does not exist, this is guesswork
  primary87: '#212121', // shadow90
  primary80: '#333333', // shadow80
  primary75: '#404040', // shadow75
  primary70: '#4d4d4d', // shadow70
  primary55: '#737373', // tint30
  primary40: '#999', // tint50 & tint60
  primary20: '#ccc', // tint80
  white: '#fff', // white

  // Main UI accent colors
  kiwiDarker: '#136618',
  kiwiDark: '#46ab4b',
  kiwi: '#83fc89',
  kiwiLight: '#b6fcb9',
  kiwiLighter: '#d7fcd8',
  lavenderDarker: '#2f248c',
  lavenderDark: '#5145bf',
  lavender: '#988cff',
  lavenderLight: '#bfb8ff',
  lavenderLighter: '#ddd9ff',
  blushDarker: '#a81171',
  blushDark: '#e866b8',
  blushMedium: '#f797d4',
  blush: '#f7c8e6',
  blushLight: '#ffe8f6',
  watermelonDarker: '#910f0f',
  watermelonDark: '#ab2222',
  watermelon: '#ff6161',
  watermelonLight: '#ff9999',
  watermelonLighter: '#ffcccc',
  // Addition accent colors
  skyDarker: '#0057ad',
  skyDark: '#4599ed',
  sky: '#85c2ff',
  skyLight: '#b8dbff',
  skyLighter: '#d9ecff',
  tangerineDarker: '#914100',
  tangerineDark: '#e37b27',
  tangerine: '#ffa963',
  tangerineLight: '#ffc596',
  tangerineLighter: '#ffe2c9',
  lemonadeDarker: '#d6a400',
  lemonadeDark: '#f0c330',
  lemonade: '#fad764',
  lemonadeLight: '#fae8af',
  lemonadeLighter: '#fff5d6',

  primary20Transparent: 'rgba(204, 204, 204, 0.5)',
};

export const Colors: ThemeColor = DefaultColors;
