import styled from 'styled-components';

export const LoadingColumn = styled.div`
  flex-grow: 1;
  width: 442px;
  margin-bottom: 100px;

  background-image: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.dark} 0px,
    ${({ theme }) => theme.colors.primary88} 100px,
    ${({ theme }) => theme.colors.dark} 200px
  );
  background-size: 100%;
  animation: shine-lines 1s infinite ease;

  @keyframes shine-lines {
    0% {
      background-position: -0;
    }
    100% {
      background-position: 442px;
    }
  }
`;
