import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { LoadingPage } from 'components/loading/loading-page/LoadingPage';
import lazyWithRetry from 'utils/import/lazyImport';
import { NoMatchingPage } from 'views/NoMatchingPage/NoMatchingPage';

const LoginPage = lazyWithRetry(
  () => import(/* webpackChunkName: "dashboard" */ 'views/login/Login'),
);

const SetupMfaPage = lazyWithRetry(
  () => import(/* webpackChunkName: "mfa-init" */ 'views/setup-mfa/SetupMfa'),
);

const VerifyMfaPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "mfa-verify" */ 'views/setup-mfa/VerifyMfa'),
);

const PasswordResetPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "password-reset" */ 'views/password-reset/PasswordReset'
    ),
);

const VerifyPasswordResetPage = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "verify-password-reset" */ 'views/verify-password-reset/VerifyPasswordReset'
    ),
);

const RegisterPage = lazyWithRetry(
  () =>
    import(/* webpackChunkName: "register-user" */ 'views/register/Register'),
);

const WithFallback = ({ children }: { children: React.ReactNode }) => {
  return <React.Suspense fallback={<LoadingPage />}>{children}</React.Suspense>;
};

export const BaseRoutes = () => {
  return (
    <Suspense fallback={<div>loading</div>}>
      <Routes>
        <Route
          path="/mfa/init"
          element={
            <WithFallback>
              <SetupMfaPage />
            </WithFallback>
          }
        />
        <Route
          path="/mfa/verify"
          element={
            <WithFallback>
              <VerifyMfaPage />
            </WithFallback>
          }
        />
        <Route
          path="/verify-password-reset/:token"
          element={
            <WithFallback>
              <VerifyPasswordResetPage />
            </WithFallback>
          }
        />
        <Route
          path="/password-reset"
          element={
            <WithFallback>
              <PasswordResetPage />
            </WithFallback>
          }
        />
        <Route
          path="/verify/:token"
          element={
            <WithFallback>
              <RegisterPage />
            </WithFallback>
          }
        />
        <Route
          path="/auth/:state"
          element={
            <WithFallback>
              <LoginPage />
            </WithFallback>
          }
        />
        <Route path="*" element={<NoMatchingPage />} />
      </Routes>
    </Suspense>
  );
};
