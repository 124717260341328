import { createGlobalStyle } from 'styled-components';
import RubikRegular from 'assets/fonts/Rubik-Regular.ttf';
import RubikMedium from 'assets/fonts/Rubik-Medium.ttf';
import { Colors } from './theme/colors';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }

  #root {
    display: flex;
    flex-direction: column;
  }

  html,
  body,
  #root {
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    /* stylelint-disable-next-line no-descending-specificity */
    ::-webkit-scrollbar-thumb {
      background-color: ${Colors.primary87};
      border-radius: 4px;
    }
    } /* stylelint-disable-next-line no-descending-specificity */
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    /* stylelint-disable-next-line no-descending-specificity */
    ::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0);
    }


  @font-face {
    font-family: Rubik;
    src: url(${RubikRegular}) format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: RubikMedium;
    src: url(${RubikMedium}) format('truetype');
    font-style: normal;
    font-weight: normal;
    font-display: swap;
  }

  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
`;
