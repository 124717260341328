import React from 'react';
import { Ripple1, Ripple2, RipplePattern } from 'assets/svg';
import {
  TomRipple,
  TheRippleEffect,
  RipplePattern1,
  RipplePattern2,
} from './Ripples.styles';

export const Ripples = () => {
  return (
    <>
      {/* <TomRipple src={RipplePattern} /> */}
      {/* <TheRippleEffect /> */}

      <RipplePattern1 src={Ripple1} initialDelay={1000} />
      <RipplePattern2 src={Ripple1} initialDelay={5000} />
    </>
  );
};
