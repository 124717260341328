import { FontFamilies } from './typography';
import { IAnnotellTheme } from './interface';
import { Colors, ThemeColor } from './colors';

// To be able to reuse the definition within the object below
const bold = {
  fontFamily: `RubikMedium, sans-serif`,
  fontWeight: 'normal',
};

const getColoredTheme = (colors: ThemeColor): IAnnotellTheme => ({
  colors,
  fontFamilies: FontFamilies,
  typography: {
    regular: {
      fontFamily: FontFamilies.default,
    },
    medium: {
      fontFamily: FontFamilies.defaultMedium,
    },
    bold: { fontFamily: FontFamilies.defaultMedium },
    normal: { fontFamily: FontFamilies.default },
  },
});

export const AnnotellTheme = getColoredTheme(Colors);
