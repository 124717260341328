import React from 'react';
import { ColumnLayout } from 'components/column-layout/ColumnLayout';
import { LoadingColumn } from './LoadingPage.styles';

export const LoadingPage = () => {
  return (
    <ColumnLayout title="Loading" subtitle="...">
      <LoadingColumn />
    </ColumnLayout>
  );
};
