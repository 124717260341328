import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider, DefaultTheme } from 'styled-components';
import { AnnotellTheme } from 'styles/theme/theme';

type ThemeContextType = {
  theme: DefaultTheme;
  setTheme: (theme: DefaultTheme) => void;
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

const ThemeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [theme, setTheme] = useState<DefaultTheme>(AnnotellTheme);
  const value: ThemeContextType = useMemo(
    () => ({
      theme,
      setTheme,
    }),
    [theme],
  );
  return (
    <ThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error(
      'useThemeContext must be used within a ThemeContextProvider',
    );
  }
  return context;
};

export { ThemeContextProvider, useThemeContext };
