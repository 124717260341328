import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { RipplePattern } from 'assets/svg';

export const TomRipple = styled(SVG)`
  width: 100vw;
  height: 100vh;
  /* max-height: 100vh; */
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
`;

export const TheRippleEffect = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 0vw,
    rgba(0, 0, 0, 0) 10vw,
    rgba(0, 0, 0, 0.7) 14vw,
    rgba(0, 0, 0, 0.7) 16vw,
    rgba(0, 0, 0, 0) 30vw
  );
  background-size: 50vw;
  animation: shine-lines 8s infinite ease-in;
  opacity: 1;
  background-repeat: no-repeat;

  @keyframes shine-lines {
    0% {
      background-position: 0vw;
      opacity: 0;
    }
    5% {
      opacity: 1;
      background-position: 1vw;
    }
    95% {
      opacity: 1;
      background-position: 44vw;
    }
    100% {
      background-position: 45vw;
      opacity: 0;
    }
  }
`;

const delay = 100;

type RippleProps = {
  initialDelay?: number;
};

const getDelay = (order: number, initialDelay: number | undefined) => {
  return (initialDelay ?? 0) + (order - 1) * delay;
};

export const RipplePattern1 = styled(SVG)<RippleProps>`
  width: 25vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  > g {
    > path {
      opacity: 0;
      animation: pulse 7s infinite;
    }

    > path:nth-child(1) {
      animation-delay: ${({ initialDelay }) => getDelay(1, initialDelay)}ms;
    }
    > path:nth-child(2) {
      animation-delay: ${({ initialDelay }) => getDelay(2, initialDelay)}ms;
    }
    > path:nth-child(3) {
      animation-delay: ${({ initialDelay }) => getDelay(3, initialDelay)}ms;
    }
    > path:nth-child(4) {
      animation-delay: ${({ initialDelay }) => getDelay(4, initialDelay)}ms;
    }
    > path:nth-child(5) {
      animation-delay: ${({ initialDelay }) => getDelay(5, initialDelay)}ms;
    }
    > path:nth-child(6) {
      animation-delay: ${({ initialDelay }) => getDelay(6, initialDelay)}ms;
    }
    > path:nth-child(7) {
      animation-delay: ${({ initialDelay }) => getDelay(7, initialDelay)}ms;
    }
    > path:nth-child(8) {
      animation-delay: ${({ initialDelay }) => getDelay(8, initialDelay)}ms;
    }
    > path:nth-child(9) {
      animation-delay: ${({ initialDelay }) => getDelay(9, initialDelay)}ms;
    }
    > path:nth-child(10) {
      animation-delay: ${({ initialDelay }) => getDelay(10, initialDelay)}ms;
    }
    > path:nth-child(11) {
      animation-delay: ${({ initialDelay }) => getDelay(11, initialDelay)}ms;
    }
    > path:nth-child(12) {
      animation-delay: ${({ initialDelay }) => getDelay(12, initialDelay)}ms;
    }
    > path:nth-child(13) {
      animation-delay: ${({ initialDelay }) => getDelay(13, initialDelay)}ms;
    }
    > path:nth-child(14) {
      animation-delay: ${({ initialDelay }) => getDelay(14, initialDelay)}ms;
    }
    > path:nth-child(15) {
      animation-delay: ${({ initialDelay }) => getDelay(15, initialDelay)}ms;
    }
    > path:nth-child(16) {
      animation-delay: ${({ initialDelay }) => getDelay(16, initialDelay)}ms;
    }
    > path:nth-child(17) {
      animation-delay: ${({ initialDelay }) => getDelay(17, initialDelay)}ms;
    }
    > path:nth-child(18) {
      animation-delay: ${({ initialDelay }) => getDelay(18, initialDelay)}ms;
    }
    > path:nth-child(19) {
      animation-delay: ${({ initialDelay }) => getDelay(19, initialDelay)}ms;
    }
    > path:nth-child(20) {
      animation-delay: ${({ initialDelay }) => getDelay(20, initialDelay)}ms;
    }
    > path:nth-child(21) {
      animation-delay: ${({ initialDelay }) => getDelay(21, initialDelay)}ms;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0.7;
    }
    20% {
      opacity: 0;
    }
  }
`;

// a-t

export const RipplePattern2 = styled(RipplePattern1)`
  width: 25vw;
  height: 100vh;
  transform: rotate(160deg);
  /* max-height: 100vh; */
  /* bottom: 0; */
  right: 0;
  top: -40vh;
  left: unset;

  > g {
    > path {
      animation: pulse 10s infinite;
    }
`;
