import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Background } from 'components/background/Background.styles';
import { BaseRoutes } from 'routes/Routes';
import { GlobalStyle } from 'styles/globalStyle.styles';
import { ThemeContextProvider } from 'hooks/contexts/ThemeContext/ThemeContext';
import { Ripples } from 'components/ripples/Ripples';

const queryClient = new QueryClient();

const App = () => {
  return (
    <ThemeContextProvider>
      <QueryClientProvider client={queryClient}>
        <Background id="portal-root">
          <Ripples />
          <GlobalStyle />
          <BrowserRouter>
            <BaseRoutes />
          </BrowserRouter>
        </Background>
      </QueryClientProvider>
    </ThemeContextProvider>
  );
};

export default App;
