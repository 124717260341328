import styled from 'styled-components';

export const CentralColumn = styled.div`
  max-width: 586px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: transparent;

  font-size: 22px;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary40};
  height: 100%;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: center;
`;

export const CenteredContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoginLogo = styled.img`
  align-self: center;
  margin-top: 34px;
  margin-left: 26px;
  width: 200px;
  height: 52px;
  position: absolute;
`;

export const LoginLead = styled.div`
  margin: 0 auto;
  max-width: 450px;
  font-size: 20px;
  line-height: 1.3;
  margin-bottom: 24px;
`;

export const LoginTitle = styled.div`
  ${({ theme }) => theme.typography.medium};
  color: ${({ theme }) => theme.colors.lavender};

  margin: 0 auto;
  max-width: 450px;
  font-size: 18px;
  line-height: 24px;
`;

export const LoginSubtitle = styled.div`
  margin: 16px auto 32px auto;
  max-width: 450px;
  font-size: 12px;
  line-height: 1.17;
  color: ${({ theme }) => theme.colors.primary20};
`;
