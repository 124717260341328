import React from 'react';
import { LogoWhite } from 'assets/svg';
import {
  CentralColumn,
  CenteredContent,
  LoginLogo,
  LoginTitle,
  LoginSubtitle,
} from './ColumnLayout.styles';

type Props = {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  children: React.ReactNode;
};

export const ColumnLayout = ({ title, subtitle, children }: Props) => {
  return (
    <>
      <LoginLogo src={LogoWhite} alt="Kognic" />
      <CentralColumn>
        <CenteredContent>
          <LoginTitle>{title}</LoginTitle>
          <LoginSubtitle>{subtitle}</LoginSubtitle>
          {children}
        </CenteredContent>
      </CentralColumn>
    </>
  );
};
